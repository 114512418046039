import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en/translation.json'
import id from './id/translation.json'

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: process.env.NODE_ENV === 'development',
  resources: {
    en: {
      translation: en
    },
    id: {
      translation: id
    }
  }
})