import { useState } from 'react'
import classNames from 'classnames'
import Barcode from 'react-barcode'
import { QRFunc } from 'react-qrbtf'
import moment from 'moment'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { Buffer } from 'buffer'

import 'i18n'
import './styles.scss'

export interface IVoucher {
  description: string
  expire_date: string
  nominal: number
  order_number: string
  product_image: string
  product_name: string
  status: string
  token_type: string
  voucher_id: string
  serial_no: string
  product: {
    code: string
  }
}


export const App = () => {
  const [searchParams] = useSearchParams()
  const data = searchParams.get('data')
  const status = searchParams.get('status')

  let voucher = {} as IVoucher
  if (data) {
    const base64Url = data.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const buff = Buffer.from(base64, 'base64')
    const payloadinit = buff.toString('ascii')
    const payload = JSON.parse(payloadinit)
    voucher = payload
  }

  const [used, setUsed] = useState<boolean>(status === 'used')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showCode, setShowCode] = useState<boolean>(false)
  const [showQr, setShowQr] = useState<boolean>(false)
  const [lang, setLang] = useState<string>('en')

  const { t, i18n } = useTranslation()

  const changeLanguage = (lang: string) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setLang(lang)
      })
      .catch(err => console.log(err))
  }

  const callback = () => {
    try {
      axios.post('https://apirewardsrv.loyal.id/callback/v1/loyalid-fe-voucher/redeem', { data })
        .then(res => console.log(res))
    } catch (e: any) {
      console.log(e)
    }
  }

  const title = voucher?.product_name || ''
  const code = voucher?.voucher_id || ''
  const order_id = voucher?.order_number || ''

  let backgroundColor = '#2d4a8a'

  if (title.toLowerCase().includes('alfamart')) backgroundColor = '#d61027'
  if (title.toLowerCase().includes('indomaret')) backgroundColor = '#d61027'
  if (title.toLowerCase().includes('grab')) backgroundColor = '#33954c'
  if (title.toLowerCase().includes('gofood')) backgroundColor = '#33954c'

  let logo = voucher?.product_image || require('images/loyalid-white.png')
  if (title.toLowerCase().includes('alfamart')) logo = require('images/alfamart.png')
  if (title.toLowerCase().includes('indomaret')) logo = require('images/indomaret.png')
  if (title.toLowerCase().includes('grab')) logo = require('images/grab.png')
  if (title.toLowerCase().includes('gofood')) logo = require('images/gofood.png')

  let mascot = require('images/none.png')
  if (title.toLowerCase().includes('alfamart')) mascot = require('images/albi.png')
  if (title.toLowerCase().includes('indomaret')) mascot = require('images/sidomar.png')

  return (
    <div className="container">
      <div className="topbar">
        <img src={require('images/loyalid-white.png')} alt="logo" className="logo" />
        <div className="language">
          <div className={classNames('selected', { id: lang === 'id' })}>
            <div className="button" />
          </div>
          <div
            className="button"
            onClick={() => changeLanguage('en')}
          >
            <img src={require('images/en.png')} alt="icon" className="icon" />
          </div>
          <div
            className="button"
            onClick={() => changeLanguage('id')}
          >
            <img src={require('images/id.png')} alt="icon" className="icon" />
          </div>
        </div>
      </div>
      {data ?
        <>
          <div className="voucher" style={{ backgroundColor }}>
            <div className="side" />
            <div className="badge">eVoucher</div>
            <img src={require('images/align.png')} alt="align" className="align" />
            <div className="nominal">Rp {title.split('Rp')[1]}</div>
            <img src={logo} alt="logo" className="logo" />
            <img src={mascot} alt="mascot" className="mascot" />
            <div className={classNames('redeemed', { used })}>
              Redeemed
            </div>
          </div>
          <div
            className="order-id"
            onClick={() => copy(order_id)}
          >
            <span>ORDER ID: {order_id}</span>
            <img src={require('images/copy.png')} alt="copy" className="copy" />
          </div>
          <div className="info">
            <div className="voucher-title">
              {title.split('Rp')[0]}
            </div>
            <div className="voucher-nominal">{title.split('Rp')[1]}</div>
            <div className="line" />
            <div className="expired-title">
              {t('expired_date')}
            </div>
            <div className="expired-date">
              <span>{moment(voucher.expire_date).format('DD MMM YYYY')}</span>
              <img src={require('images/calendar.png')} alt="date" className="icon" />
              <span>{moment(voucher.expire_date).format('hh:mm')}</span>
            </div>
          </div>

          <div className="button-wrapper">
            {used ?
              <div className="redeemed">
                <span>{t('voucher_was_redeemed')}</span>
                <img src={require('images/success.png')} alt="success" className="success" />
              </div>
              :
              <div className="voucher-note">
                <b>{t('instruction')}</b>, {t('impact')}
              </div>
            }
            <div
              className="button"
              style={{ animation: !showConfirm && !showCode && !used ? 'pulse 1s infinite' : 'none' }}
              onClick={() => {
                if (used) {
                  setShowCode(true)
                } else {
                  setShowConfirm(true)
                }
              }}
            >
              {used ? 'Show Code' : 'Redeem Voucher'}
            </div>
          </div>
          <div className="contact">{used ? t('contact_cs') : t('need_help')}</div>
          <a href="https://wa.me/6285210508269" className="whatsapp" target="_blank" rel="noreferrer">
            <img src={require('images/whatsapp.png')} alt="whatsapp" className="whatsapp-icon" />
            <span>Customer Service</span>
          </a>
          <div
            className={classNames('modal-confirm', { show: showConfirm })}
            onClick={() => setShowConfirm(false)}
          >
            <div
              className="box"
              onClick={e => e.stopPropagation()}
            >
              <div className="title">
                {t('are_you_sure')}
              </div>
              <div className="content">
                {t('time_limit_after_redeem')}
              </div>
              <div className="button-confirm">
                <div
                  className="button"
                  onClick={() => setShowConfirm(false)}
                >
                  {t('cancel')}
                </div>
                <div
                  className="button primary"
                  onClick={() => {
                    setShowConfirm(false)
                    setShowCode(true)
                    setTimeout(() => {
                      setUsed(true)
                      callback()
                    }, 200)
                  }}
                >
                  {t('yes')}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames('modal', { show: showCode })}
            onClick={() => setShowCode(false)}
          >
            <div
              className="code"
              onClick={e => e.stopPropagation()}
            >
              {showQr ?
                <QRFunc value={code} type="round" posType="round" />
                :
                <Barcode value={code} fontSize={0} />
              }
              <div
                className="voucher-code"
                onClick={() => copy(code)}
              >
                <span>{code}</span>
                <img src={require('images/copy.png')} alt="copy" className="copy" />
              </div>
              <div className="choose-code">
                <div className={classNames('selected', { qr: showQr })}>
                  <div className="button" />
                </div>
                <div
                  className="button"
                  onClick={() => setShowQr(false)}
                >
                  <img src={require('images/barcode.png')} alt="icon" className="icon" />
                  <span>BARCODE</span>
                </div>
                <div
                  className="button"
                  onClick={() => setShowQr(true)}
                >
                  <img src={require('images/qr-code.png')} alt="icon" className="icon" />
                  <span>QR CODE</span>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <div className="voucher-not-found">
          <img src={require('images/nothing.png')} alt="icon" className="icon" />
          <div>Voucher not found</div>
        </div>
      }
    </div>
  )
}
